/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import "./TeamGallery.scss";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import downarrow from "../../assets/images/chatgpt/down-arrow.png";
import imgselect from "../../assets/images/icons/image-select.png";
import gif from "../../assets/images/workspace/upscale-gif.gif";
import fav from "../../assets/images/workspace/icons/feed-fav.png";
import fav1 from "../../assets/images/workspace/icons/feed-fav1.png";
import fav2 from "../../assets/images/workspace/icons/feed-fav2.png";
import { UserContext } from "../../store/UserDetailsStore";
import { useTranslation } from "react-i18next";
import {
  CallAwsUrl,
  DeleteImagesGallery,
  GetAllImagesCurrentUserGalleryMongo,
} from "../../utils/apiCalls";
import PLanEnd from "../../components/plan-end/PLanEnd";
import WorkSpaceFeedPopUp from "../../components/WorkspaceComponents/workspacefeedpopup/WorkSpaceFeedPopUp";
import GalleryHeader from "../../components/GalleryHeader/GalleryHeader";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import FavoriteTooltip from "../../components/Tooltips/FavotiteTooltip";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  extractNameFromUrl,
  handleConvertDateTime,
} from "../../utils/workspaceUtils";
import Confirm from "../../components/confirmModal/confirm";
import Loader from "../../components/loader";

const TeamGallery = ({ handleShowSubscription }) => {
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const { updateSocketData } = useContext(UserSocketContext);
  const { isTeamChanges } = useContext(TeamDesignContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { t } = useTranslation();
  const targetRef = useRef(null);
  const galleryApiRef = useRef(false);
  const { isPrivilagesDetails } = useContext(UserContext);
  const [isPageIndex, setIsPageIndex] = useState(0);
  const [isPageSize, setIsPageSize] = useState(40);
  const [isGalleryImages, setIsGalleryImages] = useState([]);
  const [isGalleryNewImages, setIsGalleryNewImages] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [isCurrentIndex, setIsCurrentIndex] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [allImagesFetched, setAllImagesFetched] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [isGptActive, setIsGptActive] = useState(false);
  const [isGptActivePopup, setIsGptActivePopup] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [searchOptions, setSearchOptions] = useState({ valueString: "" });
  const [activeTab, setActiveTab] = useState("all");
  const [loadedImages, setLoadedImages] = useState({});
  const [isFavoriteImages, setIsFavoriteImages] = useState({});
  const [isDisable, setIsDiable] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [downloadIcon, setDownloadIcon] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const [dragStart, setDragStart] = useState(null);
  const [showConfirmModal1, setShowConfirmModal1] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [deleteIcon, setDeleteIcon] = useState(false);
  const [deletedMediaId, setDeletedMediaId] = useState([]);

  let i = 0;
  const maxImageSelection = 100;

  useEffect(() => {
    if (isPrivilagesDetails?.communityGallery === false) {
      setIsGptActive(true);
    }
  }, [isPrivilagesDetails]);

  const handleCloseAccessPopup = () => {
    setIsGptActivePopup(!isGptActivePopup);
  };

  const handleSubscription = () => {
    handleShowSubscription();
    setIsGptActivePopup(false);
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setShowScrollArrow(true);
    } else {
      setShowScrollArrow(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handleLoad = () => {
    setImageLoader(false);
  };

  const handlePopup = (image, index) => {
    if (isPrivilagesDetails?.communityGallery === false) {
      setIsGptActivePopup(true);
    } else {
      setImageLoader(true);
      setShowPopup(true);
      setIsCurrentIndex(index);
      const createdDate = new Date(image?.created);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      const hours = String(createdDate.getHours()).padStart(2, "0");
      const minutes = String(createdDate.getMinutes()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

      setPopupData({
        ...image,
        created: formattedDate,
      });
      document.body.classList.add("popup-open");
    }
  };

  useEffect(() => {
    if (isCurrentIndex >= 0 && isCurrentIndex < isGalleryImages.length) {
      const currentImage = isGalleryImages[isCurrentIndex];
      const imageElement = new Image();
      imageElement.src = currentImage.baseImage || currentImage.url;

      imageElement.onload = () => {
        setImageLoader(false);
      };

      imageElement.onerror = () => {
        setImageLoader(false);
      };
    }
  }, [isCurrentIndex]);

  const handleNext = () => {
    setImageLoader(true);
    if (isCurrentIndex < isGalleryImages?.length - 1) {
      const nextIndex = isCurrentIndex + 1;
      const nextImage = isGalleryImages[nextIndex];
      const createdDate = new Date(nextImage?.created);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      const hours = String(createdDate.getHours()).padStart(2, "0");
      const minutes = String(createdDate.getMinutes()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

      setPopupData({
        ...nextImage,
        created: formattedDate,
      });

      setIsCurrentIndex(nextIndex);
    }
  };

  const handlePrev = () => {
    setImageLoader(true);
    if (isCurrentIndex < isGalleryImages?.length - 1) {
      const nextIndex = isCurrentIndex - 1;
      const nextImage = isGalleryImages[nextIndex];
      const createdDate = new Date(nextImage?.created);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      const hours = String(createdDate.getHours()).padStart(2, "0");
      const minutes = String(createdDate.getMinutes()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

      setPopupData({
        ...nextImage,
        created: formattedDate,
      });

      setIsCurrentIndex(nextIndex);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        if (isCurrentIndex < isGalleryImages?.length - 1) {
          if (isCurrentIndex !== 0) {
            setImageLoader(true);
            const nextIndex = isCurrentIndex - 1;
            const nextImage = isGalleryImages[nextIndex];
            const createdDate = new Date(nextImage?.created);
            const day = String(createdDate.getDate()).padStart(2, "0");
            const month = String(createdDate.getMonth() + 1).padStart(2, "0");
            const year = createdDate.getFullYear();
            const hours = String(createdDate.getHours()).padStart(2, "0");
            const minutes = String(createdDate.getMinutes()).padStart(2, "0");
            const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

            setPopupData({
              ...nextImage,
              created: formattedDate,
            });

            setIsCurrentIndex(nextIndex);
          }
        }
      } else if (event.key === "ArrowRight") {
        if (isCurrentIndex < isGalleryImages.length - 1) {
          if (isCurrentIndex !== isGalleryImages.length - 1) {
            setImageLoader(true);
            const nextIndex = isCurrentIndex + 1;
            const nextImage = isGalleryImages[nextIndex];
            const createdDate = new Date(nextImage?.created);
            const day = String(createdDate.getDate()).padStart(2, "0");
            const month = String(createdDate.getMonth() + 1).padStart(2, "0");
            const year = createdDate.getFullYear();
            const hours = String(createdDate.getHours()).padStart(2, "0");
            const minutes = String(createdDate.getMinutes()).padStart(2, "0");
            const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

            setPopupData({
              ...nextImage,
              created: formattedDate,
            });

            setIsCurrentIndex(nextIndex);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isGalleryImages, isCurrentIndex]);

  const handlePopupclose = () => {
    setShowPopup(false);
    document.body.classList.remove("popup-open");
  };

  useEffect(() => {
    setIsGalleryImages([]);
    setDataNotFound(true);
    setTimeout(() => {
      const delay = isGalleryImages?.length !== 0 ? 10 : 0;
      let timeoutId;

      const debounceSearch = () => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setAllImagesFetched(false);
          galleryApiRef.current = false;
          getAllCommunityGalleryImages();
        }, delay);
      };

      debounceSearch();

      return () => {
        clearTimeout(timeoutId);
      };
    }, 0);
  }, [searchOptions, activeTab, isTeamChanges]);

  const getAllCommunityGalleryImages = () => {
    // if (!allImagesFetched) {
    if (!galleryApiRef.current) {
      galleryApiRef.current = true;
      setDataNotFound(true);
      let searchData = searchOptions;
      if (activeTab !== "all" && activeTab !== "favorite") {
        const currentValues = searchOptions?.valueString?.split(",");
        if (!currentValues?.includes("upscale")) {
          if (currentValues[0] !== "") {
            const newValues = [...currentValues, "upscale"];
            searchData = { valueString: newValues?.join(",") };
          } else {
            const newValues = ["upscale"];
            searchData = { valueString: newValues?.join(",") };
          }
        }
      }
      const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
      setDataNotFound(true);
      const data = localStorage.getItem("language");
      const payload = {
        pageIndex: 0,
        pageSize: isPageSize,
        searchString: searchData?.valueString,
        chinese: data === "ch" ? true : false,
        teamId: teamId,
        userProject: false,
        isFavorite: activeTab === "favorite" ? true : false,
      };
      if (payload) {
        setIsGalleryImages([]);
        GetAllImagesCurrentUserGalleryMongo((res) => {
          if (res.status === 200) {
            setDataNotFound(false);
            const newImages = res?.projectMedias || [];
            setIsGalleryImages(newImages);
          } else {
            setDataNotFound(false);
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    }
    // }
  };

  const checkAllImagesLoaded = useCallback(() => {
    const images = document.querySelectorAll(".column-img");
    for (const image of images) {
      if (!image?.complete) {
        return false;
      }
    }
    return true;
  }, []);

  const getAllCommunityGalleryImages1 = (isLoading) => {
    if (!allImagesFetched && !isLoading) {
      isLoading = true;

      const data = localStorage.getItem("language");
      const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
      let searchData = searchOptions;
      if (activeTab !== "all" && activeTab !== "favorite") {
        const currentValues = searchOptions?.valueString?.split(",");
        if (!currentValues?.includes("upscale")) {
          if (currentValues[0] !== "") {
            const newValues = [...currentValues, "upscale"];
            searchData = { valueString: newValues?.join(",") };
          } else {
            const newValues = ["upscale"];
            searchData = { valueString: newValues?.join(",") };
          }
        }
      }
      const index = Math.round(isGalleryImages?.length / 40);
      const payload = {
        pageIndex: index ?? 0,
        pageSize: isPageSize,
        searchString: searchData?.valueString,
        chinese: data === "ch" ? true : false,
        teamId: teamId,
        userProject: false,
        isFavorite: activeTab === "favorite" ? true : false,
      };
      if (payload) {
        GetAllImagesCurrentUserGalleryMongo((res) => {
          const newImages = res?.projectMedias || [];
          if (newImages.length === 0) {
            setAllImagesFetched(true);
          }
          setIsGalleryNewImages(res?.projectMedias);
          isLoading = false;
          setIsPageIndex((prevIndex) => prevIndex + 1);
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    }
  };

  useEffect(() => {
    let isLoading = false;

    const handleScroll = () => {
      const isNearBottom =
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 100;

      if (isNearBottom && !isLoading && !isDisable) {
        getAllCommunityGalleryImages1(isLoading);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    isPageIndex,
    isPageSize,
    searchInput,
    allImagesFetched,
    checkAllImagesLoaded,
  ]);

  const handleLoadMore = () => {
    let isLoading = false;
    getAllCommunityGalleryImages1(isLoading);
  };

  useEffect(() => {
    if (isGalleryImages?.length < 40) {
      setIsDiable(true);
    } else if (
      isGalleryImages?.length > 41 &&
      isPageIndex !== 0 &&
      isPageIndex !== 1 &&
      isGalleryNewImages?.length < 40
    ) {
      setIsDiable(true);
    } else {
      setIsDiable(false);
    }
  }, [isGalleryNewImages, isGalleryImages]);

  useEffect(() => {
    const uniqueGalleryImagesIds = new Set(
      isGalleryImages?.map((image) => image?.id)
    );
    const uniqueImages = isGalleryNewImages?.filter((newImage) => {
      return !uniqueGalleryImagesIds.has(newImage?.id);
    });
    if (uniqueImages?.length > 0) {
      setIsGalleryImages((prevImages) => [...prevImages, ...uniqueImages]);
    }
    if (isGalleryImages?.length === 40 && isPageIndex === 0) {
      setIsPageIndex(1);
    }
  }, [isGalleryImages, isGalleryNewImages, isPageIndex]);

  useEffect(() => {
    const areAllImagesLoaded = checkAllImagesLoaded();
    setAllImagesLoaded(areAllImagesLoaded);
  }, [isGalleryImages, checkAllImagesLoaded]);

  useEffect(() => {
    const favoritesMap = {};

    isGalleryImages.forEach((media) => {
      favoritesMap[media.id] = media.isFavorite;
    });

    setIsFavoriteImages(favoritesMap);
  }, [isGalleryImages]);

  const handleImageLoad = (mediaId) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [mediaId]: true,
    }));
  };

  const handleImageSelection = (imageId) => {
    if (isSelectMode) {
      if (selectedImages.includes(imageId)) {
        setSelectedImages(selectedImages.filter((id) => id !== imageId));
      } else {
        if (selectedImages.length < maxImageSelection) {
          setSelectedImages([...selectedImages, imageId]);
        } else {
          setShowAlert(true);
        }
      }
    } else {
      setSelectedImages([]);
    }
  };

  useEffect(() => {
    if (isSelectMode === false) {
      setSelectedImages([]);
    }
  }, [isSelectMode]);

  const handleCloseDownload = () => {
    setDownloadIcon(false);
  };

  const handleDownload = async () => {
    setDownloadIcon(false);
    const zip = new JSZip();
    const imgFolder = zip.folder("images");
    const currentDateTime = new Date().toString();
    const zipName = "AIRILabImages@" + currentDateTime;

    const callAwsUrlAsync = (updatedLink) => {
      return new Promise((resolve, reject) => {
        CallAwsUrl((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(new Error(res.message || "Failed to download image"));
          }
        }, updatedLink);
      });
    };

    const filenames = {};

    const sanitizeFilename = (name) => {
      return name.replace(/[/\\?%|"*<>]/g, "_");
    };

    for (const imageId of selectedImages) {
      const selectedImage = isGalleryImages.find(
        (image) => image.id === imageId
      );
      const imageData = selectedImage;

      const date = await handleConvertDateTime(imageData?.created);
      const updatedLink = selectedImage.url;
      const imageName = extractNameFromUrl(imageData?.url);

      try {
        const data = await callAwsUrlAsync(updatedLink);
        let downloadName = `${imageData?.username}&${imageData?.projectName}&${imageData?.generationId}&${imageName}&${date}.jpg`;
        downloadName = sanitizeFilename(downloadName);
        i = i + 1;
        if (filenames[downloadName]) {
          const count = filenames[downloadName];
          const extensionIndex = downloadName.lastIndexOf(".jpg");
          const nameWithoutExtension = downloadName.substring(
            0,
            extensionIndex
          );
          downloadName = `${nameWithoutExtension}_${count}.jpg`;
          filenames[downloadName] = count + 1;
        } else {
          filenames[downloadName] = 1;
        }

        imgFolder.file(downloadName, data, { base64: true });
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, zipName + ".zip");
      })
      .catch((error) => {
        console.error("Error generating zip:", error);
      });

    setSelectedImages([]);
  };

  const handleCloseDelete = () => {
    setDeleteIcon(false);
  };

  const handleDeleteImages = () => {
    setIsLoader(true);
    const payload = {
      projectId: null,
      mediaIds: selectedImages,
    };
    if (payload) {
      DeleteImagesGallery((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          galleryApiRef.current = false;
          getAllCommunityGalleryImages();
          setShowConfirmModal(false);
          setDeleteIcon(false);
          setIsLoader(false);
          setShowConfirmModal1(true);
          setAlertText("Images Deleted Successfully");
          setIsPageIndex(0);
          setSelectedImages([]);
        } else {
          setIsLoader(false);
          setDeleteIcon(false);
          setShowConfirmModal(false);
          setShowConfirmModal1(true);
          setAlertText(message);
          setIsPageIndex(0);
          setSelectedImages([]);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleDragStart = async (e, image) => {
    e.preventDefault();
    const url = image?.url;
    const date = await handleConvertDateTime(image?.created);
    const fileName = `${image?.username}_${image?.projectName}_${date}_${image?.generationId}.jpg`;

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName;
    // anchor.click();
  };

  const numRows = 5;
  const imagesPerPage = numRows * Math.ceil(window.innerHeight / 100);
  const numCols = Math.ceil(isGalleryImages?.length / numRows);

  const rows = [];
  for (let row = 0; row < numRows; row++) {
    const cols = [];
    for (let col = 0; col < numCols; col++) {
      const index = col * numRows + row;
      if (index < isGalleryImages?.length) {
        const image = isGalleryImages[index];
        cols.push(
          <div key={image?.id} className="column">
            <div
              className={`column-cont ${
                selectedImages?.includes(image?.id) ? "selected" : ""
              }`}
            >
              <div
                className={`column-cont__sec ${
                  isFavoriteImages[image?.id] ? "favorite" : ""
                }`}
              >
                <img className="icon" src={imgselect} alt="select-icon" />
                <img
                  className={`column-img ${
                    selectedImages?.includes(image?.id) ? "selected" : ""
                  }`}
                  src={image?.thumbnail || image?.url}
                  alt={image?.id}
                  data-image-id={image?.id}
                  onLoad={() => handleImageLoad(image.id)}
                  onDragStart={(e) => handleDragStart(e, image)}
                />
                <div
                  className="favorites"
                  onClick={
                    image?.url
                      ? isSelectMode === true
                        ? () => handleImageSelection(image?.id)
                        : () => handlePopup(image, index)
                      : undefined
                  }
                >
                  <div
                    className="fav-icon"
                    style={{
                      display: loadedImages[image?.id] ? "block" : "none",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img src={fav2} className="fav" alt="favorite" />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <FavoriteTooltip text={t("favorite")} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        cols.push(<div key={col} className="column"></div>);
      }
    }
    rows.push(
      <div key={row} className="row">
        {cols}
      </div>
    );
  }

  const [originalImage, setOriginalImage] = useState(new Image());
  const [originalPrevImage, setOriginalPrevImage] = useState(new Image());
  const [originalNextImage, setOriginalNextImage] = useState(new Image());
  const [alternateImage, setAlternateImage] = useState(new Image());

  useEffect(() => {
    originalImage.src = popupData?.url;
    if (isCurrentIndex !== 0) {
      originalPrevImage.src = isGalleryImages[isCurrentIndex - 1]?.url;
    }
    if (isGalleryImages?.length !== isCurrentIndex + 1) {
      originalNextImage.src = isGalleryImages[isCurrentIndex + 1]?.url;
    }

    if (popupData?.baseImage !== null) {
      alternateImage.src = popupData?.baseImage;
    }
  }, [popupData, isCurrentIndex]);

  const dynamicValue1 = popupData?.toolset?.split(",")[0]?.toUpperCase();

  const dynamicValue2 = popupData?.toolset?.split(",")[1]?.toUpperCase();

  const downloadTitle = `${t("download_text")} ${selectedImages?.length} ${t(
    "image_text"
  )}`;
  const downloadContent = `${t("download_the_selected")} ${
    selectedImages?.length
  } ${t("image?")}`;
  const deleteTitle = `${t("delete")} ${selectedImages?.length} ${t(
    "image_text"
  )}`;
  const deleteContent = `${t("delete_the_selected")} ${
    selectedImages?.length
  } ${t("image?")}`;

  useEffect(() => {
    setShowSlider(false);
  }, [popupData]);

  const handleShowSlider = () => {
    setShowSlider(true);
  };

  const handleMouseDown = (e) => {
    if (!isSelectMode) return;
    const rect = targetRef.current.getBoundingClientRect();
    setDragStart({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseMove = (e) => {
    if (!isSelectMode || !dragStart) return;
    const rect = targetRef.current.getBoundingClientRect();
    const dragCurrent = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
    if (
      Math.abs(dragStart.x - dragCurrent.x) > 5 ||
      Math.abs(dragStart.y - dragCurrent.y) > 5
    ) {
      selectImagesInPath(dragStart, dragCurrent);
    }
  };

  const handleMouseUp = (e) => {
    if (!isSelectMode || !dragStart) return;
    setDragStart(null);
  };

  const touchToMouse = (e) => {
    if (e.touches && e.touches[0]) {
      return {
        clientX: e.touches[0].clientX,
        clientY: e.touches[0].clientY,
        target: e.target,
      };
    }
    return { clientX: 0, clientY: 0, target: e.target }; // Or another value indicating an error or non-existent touch
  };

  const touchStartHandler = (e) => {
    const touchMouseEvent = touchToMouse(e);
    handleMouseDown(touchMouseEvent);
  };

  const touchMoveHandler = (e) => {
    const touchMouseEvent = touchToMouse(e);
    handleMouseMove(touchMouseEvent);
  };

  const touchEndHandler = (e) => {
    const touchMouseEvent = touchToMouse(e);
    handleMouseUp(touchMouseEvent);
  };

  const selectImagesInPath = (start, curr) => {
    const rect = targetRef.current.getBoundingClientRect();
    const newSelected = isGalleryImages.filter((image) => {
      const imgElement = document.querySelector(
        `img[data-image-id="${image.id}"]`
      );
      if (!imgElement) return false; // Skip if no element is found
      const imgRect = imgElement.getBoundingClientRect();
      if (
        imgRect.left + imgRect.width > rect.left + curr.x &&
        imgRect.left < rect.left + curr.x &&
        imgRect.top + imgRect.height > rect.top + curr.y &&
        imgRect.top < rect.top + curr.y
      ) {
        if (selectedImages.length < maxImageSelection) {
          setSelectedImages((prevSelectedImages) => {
            if (!prevSelectedImages.includes(image.id)) {
              return [...new Set([...prevSelectedImages, image.id])];
            }
            return prevSelectedImages;
          });
        } else {
          setShowAlert(true);
          handleMouseUp();
        }
      }
    });
  };

  return (
    <UserPortalLayout>
      <div className="tgallery">
        <SideNavComponent />
        <div className="tgallery__container">
          <div className="tgallery__sections">
            <div className="tgallery__main">
              <div className="tgallery__main__section">
                <GalleryHeader
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  isFavoriteTab={true}
                  isShowIcons={true}
                  showDeleteIcon={isActiveTeam?.roleId === 1 ? true : false}
                  isLoading={dataNotFound}
                  isSelectMode={isSelectMode}
                  setIsSelectMode={setIsSelectMode}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  downloadIcon={downloadIcon}
                  setDownloadIcon={setDownloadIcon}
                  setAlertText={setAlertText}
                  setShowConfirmModal={setShowConfirmModal}
                  setAlertContent={setAlertContent}
                  deleteIcon={deleteIcon}
                  setDeleteIcon={setDeleteIcon}
                  directDelete={true}
                />
                <div
                  className="tgallery__main__images__container"
                  ref={targetRef}
                  data-scrollable="true"
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                  onTouchStart={touchStartHandler}
                  onTouchEnd={touchEndHandler}
                  onTouchMove={touchMoveHandler}
                >
                  {isGalleryImages?.length !== 0 ? (
                    rows?.map((row, rowIndex) => (
                      <div key={`row-all-${rowIndex}`} className="row">
                        {row?.props?.children?.map((col, colIndex) => (
                          <div key={`col-all-${rowIndex}-${colIndex}`}>
                            {col}
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="gif-container">
                      {dataNotFound ? (
                        <img src={gif} alt="gif-loader" />
                      ) : (
                        <p>{t("no_data_found")}</p>
                      )}
                    </div>
                  )}
                </div>
                <div className="gallery__main__section__button">
                  <button disabled={isDisable} onClick={handleLoadMore}>
                    {isDisable ? t("all_loaded") : t("load_more")}
                  </button>
                </div>
                {showScrollArrow && (
                  <div className="gallery__main__section__scroll">
                    <img
                      src={downarrow}
                      alt="down-arrow"
                      onClick={handleClick}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showPopup && (
          <WorkSpaceFeedPopUp
            popupData={popupData}
            isCurrentIndex={isCurrentIndex}
            handlePopupPrev={handlePrev}
            handlePopupNext={handleNext}
            handlePopupclose={handlePopupclose}
            handlePopupShowSlider={handleShowSlider}
            popupShowSlider={showSlider}
            popupImageLoader={imageLoader}
            popupDynamicValue1={dynamicValue1}
            popupDynamicValue2={dynamicValue2}
            showStealth={false}
            handlePopupLoad={handleLoad}
            isGalleryImages={isGalleryImages}
            showAllIcons={false}
            showDownloadIcon={true}
            showDeleteIcon={isActiveTeam?.roleId === 1 ? true : false}
          />
        )}
        {downloadIcon && (
          <Confirm
            isCancelRequired={true}
            confirmTitle={downloadTitle}
            content={downloadContent}
            onConfirm={handleDownload}
            onCancel={handleCloseDownload}
            cancelButtonText={t("cancel")}
            buttonText={t("download")}
          />
        )}
        {deleteIcon && (
          <Confirm
            isCancelRequired={true}
            confirmTitle={deleteTitle}
            content={deleteContent}
            onConfirm={handleDeleteImages}
            onCancel={handleCloseDelete}
            cancelButtonText={t("cancel")}
            buttonText={t("delete")}
          />
        )}
        {showConfirmModal && (
          <Confirm
            buttonText={"OK"}
            isCancelRequired={false}
            confirmTitle={alertText}
            content={alertContent}
            onConfirm={() => {
              setShowConfirmModal(false);
            }}
            onCancel={() => {
              setShowConfirmModal(false);
            }}
          />
        )}
        {showAlert && (
          <Confirm
            buttonText={"OK"}
            isCancelRequired={false}
            confirmTitle={t("not_more")}
            onConfirm={() => {
              setShowAlert(false);
            }}
            onCancel={() => {
              setShowAlert(false);
            }}
          />
        )}
        {showConfirmModal1 && (
          <Confirm
            buttonText={"OK"}
            isCancelRequired={false}
            confirmTitle={alertText}
            onConfirm={() => {
              setShowConfirmModal1(false);
            }}
            onCancel={() => {
              setShowConfirmModal1(false);
            }}
          />
        )}
        {isLoader && <Loader />}
        {isGptActive === true && (
          <>
            {isGptActivePopup === true && (
              <PLanEnd
                handleCloseAccessPopup={handleCloseAccessPopup}
                handleSubscription={handleSubscription}
              />
            )}
          </>
        )}
      </div>
    </UserPortalLayout>
  );
};

export default TeamGallery;
