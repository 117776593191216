/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import "./Header.scss";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import logo from "../../assets/images/icons/airi-lab-beta_tag.png";
import loader from "../../assets/images/icons/loading.png";
import userIcon from "../../assets/images/icons/user-icon.png";
import homeicon from "../../assets/images/workspace/icons/home-icon.png";
import notificationicon from "../../assets/images/teams/icons/notification-new.png";
import globea from "../../assets/images/icons/globe-a.png";
import globed from "../../assets/images/icons/globe-d.png";
import upgradeicon from "../../assets/images/teams/icons/upgrade.png";
import { UserContext } from "../../store/UserDetailsStore";
import { ToolsetDataContext } from "../../store/WorkSpaceToolsetData";
import { useTranslation } from "react-i18next";
import { GetLatestProjectId } from "../../utils/apiCalls";
import TootipTop from "../Tooltips/TooltipTop";
import Button from "../button";
import { CurrentProjectContext } from "../../store/CurrentProjectData";
import Confirm from "../confirmModal/confirm";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { ClientDataContext } from "../../store/ClientData";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import PrivateGalleryIcon from "../../assets/IconComponents/PrivateGalleryIcon";
import AccountIcon from "../../assets/IconComponents/AccountIcon";
import ContactIcon from "../../assets/IconComponents/ContactIcon";
import Logout from "../../assets/IconComponents/Logout";
import { Popover } from "antd";

const Header = ({ showLoadingPage }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { toolsetData } = useContext(ToolsetDataContext);
  const { currentProjectData } = useContext(CurrentProjectContext);
  const { responsiveData, isRedirect } = useContext(ResponsiveDataContext);
  const { clientData } = useContext(ClientDataContext);
  const { updateSocketData, handleShowSubscription } =
    useContext(UserSocketContext);
  const { isLoginUserDetails } = useContext(UserContext);
  const { getAllUserTeams, isTeamChanges, getUserDetailsOfteam, teamsList } =
    useContext(TeamDesignContext);
  const { isTeamInvite, handleTeamNofications } = useContext(UserSocketContext);

  const token = localStorage.getItem("token");
  const isLoginUser = localStorage.getItem("loginUser");
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const lang = localStorage.getItem("language");

  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const [confirmLogOut, setconfirmLogOut] = useState(false);
  const [activeMenu, setActiveMenu] = useState(pathname ?? "");
  const [hideLinks, setHideLinks] = useState(true);
  const [open, setOpen] = useState(false);

  const isLogin =
    isLoginUser !== "undefined"
      ? JSON.parse(isLoginUser)
      : localStorage.setItem("loginUser", false);

  const url = window.location.pathname;
  const substring1 = "workspace";
  let getval1 = url.includes(substring1);
  const substring2 = "airi-bot";
  let getval2 = url.includes(substring2);
  const substring3 = "sign-in";
  let getval3 = url.includes(substring3);
  let getval4 = pathname === "/projects";

  const hideHeader =
    pathname === "/terms-of-services" ||
    pathname === "/privacy-policy" ||
    pathname === "/renewal-policy";

  const hideButtons = pathname?.includes("tutorial-data");

  useEffect(() => {
    handleActiveLink(pathname);
  }, [pathname]);

  useEffect(() => {
    if (
      isActiveTeam === undefined ||
      isActiveTeam === null ||
      isActiveTeam === "undefined"
    ) {
      if (teamsList?.length !== 0) {
        const data = teamsList?.find((team) => team?.roleId === 0);
        if (data) {
          localStorage.setItem("activeTeam", JSON.stringify(data));
        }
      }
    } else if (
      isActiveTeam?.roleId === null ||
      isActiveTeam?.roleId === undefined
    ) {
      if (teamsList?.length !== 0) {
        const data = teamsList?.find((team) => team?.roleId === 0);
        if (data) {
          localStorage.setItem("activeTeam", JSON.stringify(data));
        }
      }
    }
  }, [teamsList]);

  useEffect(() => {
    if (token) {
      getAllUserTeams();
    }
  }, [token]);

  const handleEditProjectName = () => {
    currentProjectData?.event();
  };

  const handleSignIn = () => {
    navigate("/sign-in");
  };

  let activeStyle = {
    opacity: 1,
    fontWeight: 600,
  };

  const dataacc = {
    heading: "| Hub",
    icon: "icon",
    loaderpage: "",
    account: "user",
    hideshowcase: "showcase",
    hidesignin: "sign-in",
  };

  useEffect(() => {
    getLatestProjectId();
    getUserDetailsOfteam();
  }, [isTeamChanges]);

  useEffect(() => {
    if (clientData && clientData.favIcon) {
      const linkForFavicon = document.querySelector("link[rel*='icon']");
      if (linkForFavicon) {
        linkForFavicon.href = clientData.favIcon;
      }
    }
  }, [clientData]);

  const getLatestProjectId = () => {
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    if (token) {
      GetLatestProjectId((res) => {
        if (res?.status === 200) {
          localStorage.setItem("getLatestProjectId", res?.data?.projectId);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, teamId);
    }
  };

  const handleWorkspace = () => {
    if (
      localStorage.getItem("getLatestProjectId") === "undefined" ||
      localStorage.getItem("getLatestProjectId") === null ||
      localStorage.getItem("getLatestProjectId") === "null"
    ) {
      let projectId = toolsetData?.isSetLatestProjectCreated;
      return `/workspace/${projectId}`;
    } else {
      return `/workspace/${localStorage.getItem("getLatestProjectId")}`;
    }
  };

  const handleActiveMenu = () => {
    setIsActiveMenu(!isActiveMenu);
  };

  const handleLogout = () => {
    setconfirmLogOut(!confirmLogOut);
  };

  const onConfirm = () => {
    setconfirmLogOut(false);
    updateSocketData({
      isSocketClose: true,
    });
    const getOldVersion = localStorage.getItem("currentVersion");
    const lang = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("currentVersion", getOldVersion);
    localStorage.setItem("language", lang);
    // navigate("/sign-in", { replace: true });
    // window.location.reload();
    // Redirect to sign-in and reload
    window.location.href = "/sign-in";
  };

  const handleNavigateCheck = (e, url) => {
    if (isRedirect?.length !== 0 && getval1) {
      const confirmed = window.confirm(t("exit_message"));
      if (confirmed) {
        navigate(url);
      } else {
        window.history.pushState(null, window.location.pathname);
        e.preventDefault();
      }
    } else {
      navigate(url);
    }
  };

  const handleActiveLink = (link) => {
    if (isRedirect?.length === 0) {
      setActiveMenu(link);
    }
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const handleActiveLanguage = (e) => {
    if (e === 1) {
      localStorage.setItem("language", "ch");
      i18n.changeLanguage("ch");
    } else {
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    }
  };

  return (
    <div className="header">
      <div className="header__container">
        <div className="header__nav">
          {showLoadingPage ? (
            <>
              <div className="header__nav__logo" key={lang}>
                {clientData?.logo !== undefined ? (
                  <NavLink to="/">
                    <img
                      className={"logo"}
                      src={clientData?.logo}
                      alt="AIRI-logo"
                    />
                  </NavLink>
                ) : (
                  <NavLink to="/">
                    <img src={logo} alt="AIRI-logo" />
                  </NavLink>
                )}
                <div className="header__nav__logo__right">
                  <p>{t("header_content")}</p>
                </div>
              </div>
              <div className="header__nav__menus">
                <div className="header__nav__load">
                  <p>{t("loading")}</p>
                  <img src={loader} alt="loader" />
                </div>
              </div>
            </>
          ) : (
            <>
              {token ? (
                <>
                  <div className="header__nav__logo">
                    {clientData?.logo !== undefined ? (
                      <NavLink to="/">
                        <img
                          className={"logo"}
                          onClick={(e) => handleNavigateCheck(e, "/")}
                          src={clientData?.logo}
                          alt="AIRI-logo"
                        />
                      </NavLink>
                    ) : (
                      <NavLink to="/">
                        <img
                          className={"logo"}
                          onClick={(e) => handleNavigateCheck(e, "/")}
                          src={logo}
                          alt="AIRI-logo"
                        />
                      </NavLink>
                    )}
                    {!hideButtons && (
                      <>
                        {!hideHeader && (
                          <div className="header__nav__logo__right">
                            <div
                              className="tooltip-top"
                              style={{
                                position: "relative",
                              }}
                            >
                              <NavLink to="/projects">
                                <div
                                  className="header__nav__logo__right__content"
                                  onClick={(e) =>
                                    handleNavigateCheck(e, "/projects")
                                  }
                                  style={{
                                    cursor: getval4 ? "not-allowed" : "pointer",
                                  }}
                                >
                                  <img
                                    src={homeicon}
                                    alt="home-icon"
                                    style={{
                                      cursor: getval4
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                  />
                                  {getval1 === true || getval4 === true ? (
                                    <>
                                      <p>{t("workshop")}</p>
                                      <p>{"/"}</p>
                                      <p
                                      // className="team"
                                      >
                                        {isActiveTeam?.teamId === 0.1
                                          ? t("my_space")
                                          : isActiveTeam?.teamName}
                                      </p>
                                      {getval4 !== true && (
                                        <>
                                          <p>{"/"}</p>
                                          <p>
                                            {currentProjectData?.projectName}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <p // className="team"
                                    >
                                      {t("workshop")}
                                    </p>
                                  )}
                                </div>
                              </NavLink>
                              <div className="tooltip-show">
                                {!responsiveData && (
                                  <TootipTop
                                    TooltipContent={
                                      getval4
                                        ? t("you_are_already_in_worshop")
                                        : t("project_tooltip")
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!hideButtons && (
                    <div className="header__nav__menus">
                      <div className="header__nav__user">
                        {!hideHeader && (
                          <>
                            {isActiveTeam?.teamId === 0.1 ? (
                              <button
                                className="upgrade-button"
                                onClick={handleShowSubscription}
                              >
                                <img src={upgradeicon} alt="upgrade-icon" />
                                <span>{t("upgrade_button")}</span>
                              </button>
                            ) : (
                              <button className="team-button">
                                <img
                                  src={isActiveTeam?.teamIcon}
                                  alt="upgrade-icon"
                                />
                                <span>{isActiveTeam?.teamName}</span>
                              </button>
                            )}
                          </>
                        )}
                        <div className="header__nav__user__team">
                          <div className="header__nav__user__team__buttons">
                            <div
                              className="header__nav__user__team__buttons__notification"
                              onClick={handleTeamNofications}
                            >
                              {isTeamInvite?.showRedDot === true && (
                                <div className="red-dot"></div>
                              )}
                              <img
                                src={notificationicon}
                                alt="notification-icon"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`header__nav__user__icon ${
                            isActiveMenu ? "active" : ""
                          }`}
                          onClick={handleActiveMenu}
                        >
                          <img
                            src={
                              isLoginUserDetails
                                ? isLoginUserDetails?.avatarUrl
                                : userIcon
                            }
                            alt="user-icon"
                            className="usericon"
                          />
                          <div className="header__nav__user__menu menu">
                            <div className="header__nav__user__menu__links">
                              <div className="header__nav__user__menu__link">
                                {getval1 === false ? (
                                  <NavLink
                                    to="/gallery"
                                    style={({ isActive }) =>
                                      isActive ? activeStyle : undefined
                                    }
                                    onClick={() => handleActiveLink("/gallery")}
                                    className={`${
                                      activeMenu === "/gallery" && "active1"
                                    }`}
                                  >
                                    <PrivateGalleryIcon
                                      fill={
                                        activeMenu === "/gallery"
                                          ? "#ffffff"
                                          : "#263238"
                                      }
                                      size={responsiveData ? "18" : "22"}
                                    />
                                    <span>{t("gallery")}</span>
                                  </NavLink>
                                ) : (
                                  <a
                                    onClick={(e) => {
                                      handleNavigateCheck(e, "/gallery");
                                      handleActiveLink("/gallery");
                                    }}
                                    className={`${
                                      activeMenu === "/gallery" && "active1"
                                    }`}
                                  >
                                    <PrivateGalleryIcon
                                      fill={
                                        activeMenu === "/gallery"
                                          ? "#ffffff"
                                          : "#263238"
                                      }
                                      size={responsiveData ? "18" : "22"}
                                    />
                                    <span>{t("gallery")}</span>
                                  </a>
                                )}
                              </div>
                              <div className="header__nav__user__menu__link">
                                {getval1 === false ? (
                                  <NavLink
                                    to={{
                                      pathname: "/account",
                                      state: { dataacc },
                                    }}
                                    style={({ isActive }) =>
                                      isActive ? activeStyle : undefined
                                    }
                                    onClick={() => handleActiveLink("/account")}
                                    className={`${
                                      activeMenu === "/account" && "active1"
                                    }`}
                                  >
                                    <AccountIcon
                                      fill={
                                        activeMenu === "/account"
                                          ? "#ffffff"
                                          : "#263238"
                                      }
                                      size={responsiveData ? "18" : "22"}
                                    />
                                    <span>{t("account")}</span>
                                  </NavLink>
                                ) : (
                                  <a
                                    onClick={(e) => {
                                      handleNavigateCheck(e, "/account");
                                      handleActiveLink("/account");
                                    }}
                                    className={`${
                                      activeMenu === "/account" && "active1"
                                    }`}
                                  >
                                    <AccountIcon
                                      fill={
                                        activeMenu === "/account"
                                          ? "#ffffff"
                                          : "#263238"
                                      }
                                      size={responsiveData ? "18" : "22"}
                                    />
                                    <span>{t("account")}</span>
                                  </a>
                                )}
                              </div>
                              <div className="header__nav__user__menu__link">
                                {getval1 === false ? (
                                  <NavLink
                                    to="/contact"
                                    style={({ isActive }) =>
                                      isActive ? activeStyle : undefined
                                    }
                                    onClick={() => handleActiveLink("/contact")}
                                    className={`${
                                      activeMenu === "/contact" && "active1"
                                    }`}
                                  >
                                    <ContactIcon
                                      fill={
                                        activeMenu === "/contact"
                                          ? "#ffffff"
                                          : "#263238"
                                      }
                                      size={responsiveData ? "18" : "22"}
                                    />
                                    <span>{t("contact")}</span>
                                  </NavLink>
                                ) : (
                                  <a
                                    onClick={(e) => {
                                      handleNavigateCheck(e, "/contact");
                                      handleActiveLink("/contact");
                                    }}
                                    className={`${
                                      activeMenu === "/contact" && "active1"
                                    }`}
                                  >
                                    <ContactIcon
                                      fill={
                                        activeMenu === "/contcat"
                                          ? "#ffffff"
                                          : "#263238"
                                      }
                                      size={responsiveData ? "18" : "22"}
                                    />
                                    <span>{t("contact")}</span>
                                  </a>
                                )}
                              </div>
                              <div className="header__nav__user__menu__link">
                                <a onClick={handleLogout}>
                                  <Logout size={responsiveData ? "18" : "22"} />
                                  <span>{t("logout")}</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="header__nav__logo">
                    {clientData?.logo !== undefined ? (
                      <NavLink to="/">
                        <img
                          className={"logo"}
                          src={clientData?.logo}
                          alt="AIRI-logo"
                        />
                      </NavLink>
                    ) : (
                      <NavLink to="/">
                        <img className={"logo"} src={logo} alt="AIRI-logo" />
                      </NavLink>
                    )}
                  </div>
                  <div className="header__nav__menus">
                    {/* <div className="header__nav__link">
                      <Button
                        buttonClass={"button_link"}
                        buttonText={t("showcase")}
                        isBtnDisabled={true}
                      />
                      <div className="tooltip-coming">
                        {!responsiveData && (
                          <TooltipTopChecks TooltipContent={t("coming_soon")} />
                        )}
                      </div>
                    </div> */}
                    <Popover
                      overlayClassName="custom-popover-language"
                      content={
                        <>
                          <h6
                            onClick={() => handleActiveLanguage(1)}
                            className={`language ${
                              lang === "ch" ? "active" : ""
                            }`}
                          >
                            简体中文
                          </h6>
                          <h6
                            onClick={() => handleActiveLanguage(2)}
                            className={`language ${
                              lang === "en" ? "active" : ""
                            }`}
                          >
                            English
                          </h6>
                        </>
                      }
                      arrow={false}
                      trigger="click"
                      placement="bottomRight"
                      open={open}
                      onOpenChange={handleOpen}
                    >
                      <button className="header__nav__language">
                        <img src={globea} className="globea" alt="globe-icon" />
                        <img src={globed} className="globed" alt="globe-icon" />
                        <h5>{lang === "en" ? "English" : "简体中文"}</h5>
                      </button>
                    </Popover>
                    <div className="header__nav__button">
                      <Button
                        buttonClass={"button"}
                        buttonText={t("signin")}
                        buttonClick={handleSignIn}
                        isBtnDisabled={getval3}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {confirmLogOut && (
        <Confirm
          isCancelRequired={true}
          confirmTitle={t("log_heading")}
          content={t("log_content1")}
          content1={t("log_content2")}
          onConfirm={onConfirm}
          onCancel={handleLogout}
          cancelButtonText={t("cancel")}
          buttonText={t("log_out_me")}
        />
      )}
    </div>
  );
};

export default Header;
